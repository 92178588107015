<template>
  <div class="my-4">
    <h3>{{ $t("BONUS_RELEVANCE") }}</h3>
    <p class="mb-4">{{ $t("BONUS_RELEVANCE_TEXT") }}</p>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTION_BONUS_RELEVANCE_LINK") }}</p>
      <select
        class="form-select"
        :disabled="activeWaveReadonly"
        v-model="model.bonusRelevanceType"
        @change="updateModel"
      >
        <option value="" v-show="!model.bonusRelevanceType">
          {{ $t("PLEASE_SELECT") }}
        </option>
        <option value="margin">{{ $t("MARGIN") }}</option>
        <option value="incentive">{{ $t("INCENTIVE") }}</option>
        <option value="competition">{{ $t("COMPETITION") }}</option>
        <option value="other">{{ $t("OTHER") }}</option>
        <option value="no">{{ $t("NO_LINK") }}</option>
      </select>
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("BONUS_RELEVANCE_DESCRIPTION") }}</p>
      <textarea
        v-model="model.bonusRelevanceDescription"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_BONUS_RELEVANCE_CHANGE") }}</p>
      <Switch
        :initValue="model.bonusRelevanceChanged"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.bonusRelevanceChanged = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askWhatAndWhyChanged()">
      <p class="sublines">{{ $t("BONUS_RELEVANCE_WHAT_CHANGED") }}</p>
      <textarea
        v-model="model.bonusRelevanceWhatChanged"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4" v-if="askWhatAndWhyChanged()">
      <p class="sublines">{{ $t("BONUS_RELEVANCE_WHY_CHANGED") }}</p>
      <textarea
        v-model="model.bonusRelevanceWhyChanged"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_REMARKS") }}</p>
      <textarea
        v-model="model.questionsAndRemarks"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "BonusRelevance",
  components: {
    Switch,
  },
  async setup() {
    const store = useStore();
    let model = store.getters["waves/getBonusRelevanceSetup"];
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      await this.$store.dispatch("waves/updateBonusRelevanceSetup", this.model);
      this.model = this.$store.getters["waves/getBonusRelevanceSetup"];
      this.$forceUpdate();
    },
    askWhatAndWhyChanged() {
      return this.model.bonusRelevanceChanged === true;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
select {
  max-width: 200px;
}
</style>
