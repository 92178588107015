<template>
  <div class="h-100 container-fluid p-0 d-flex flex-column">
    <div class="text-center">
      <h2>{{ $t("GENERAL_SETUP") }}</h2>
    </div>
    <div class="flex-full-height router-view-scrollable-content">
      <div>
        <p class="text-center pb-2">
          {{ $t("GENERAL_SETUP_TEXT_LEGAL") }}
          <br />
          {{ $t("GENERAL_SETUP_TEXT_BUDGET") }}
        </p>
      </div>
      <AudioVideoRecordings />
      <hr />
      <RoundTable />
      <hr />
      <FeedbackShops />
      <hr />
      <BonusRelevance />
      <hr />
      <LocalWave />
      <hr />
      <TimingRequirements />
      <hr />
      <WaveTiming />
    </div>
    <div class="general-setup-button-container pb-3 pt-3 global-padding">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push(`/projects/${$route.params.projectGuid}/waves`)"
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          @click="
            $router.push(
              `/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/dealer-adresses`
            )
          "
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AudioVideoRecordings from "../components/GeneralSetup/AudioVideoRecordings.vue";
import RoundTable from "../components/GeneralSetup/RoundTable.vue";
import FeedbackShops from "../components/GeneralSetup/FeedbackShops.vue";
import BonusRelevance from "../components/GeneralSetup/BonusRelevance.vue";
import LocalWave from "../components/GeneralSetup/LocalWave.vue";
import WaveTiming from "../components/GeneralSetup/WaveTiming.vue";
import TimingRequirements from "../components/GeneralSetup/TimingRequirements.vue";
export default {
  name: "GeneralSetup",
  components: {
    AudioVideoRecordings,
    RoundTable,
    FeedbackShops,
    BonusRelevance,
    LocalWave,
    WaveTiming,
    TimingRequirements,
  },
  async setup() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.general-setup-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
textarea {
  resize: none;
}
</style>
