<template>
  <div class="my-4">
    <h3>{{ $t("WAVE_TIMING_HEADLINE") }}</h3>
    <div class="mb-4">
      <p class="sublines">
        {{ $t("AGREE_PROPOSED_TIMING") }}
      </p>
      <Switch
        :initValue="waveTiming.agreesWithTiming"
        :disabled="activeWaveReadonly"
        v-on:changed="
          async (checked) => {
            waveTiming.agreesWithTiming = checked;
            await updateModel(waveTiming);
          }
        "
      />
    </div>
    <div class="mb-4" v-show="!waveTiming.agreesWithTiming">
      <p class="sublines">
        {{ $t("NO_AGREE_PLEASE_COMMENT") }}
      </p>
      <textarea
        class="form-control"
        v-model="waveTiming.noAgreeComment"
        :readonly="activeWaveReadonly"
        @change="updateModel(waveTiming)"
      ></textarea>
    </div>
    <div class="table-responsive w-100">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="border-0"></th>
            <th scope="col">{{ $t("START") }}</th>
            <th scope="col">{{ $t("END") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="period in activePeriods" :key="period.id">
            <th scope="row" class="border-0">
              {{ period.name }}
            </th>
            <td>
              <input
                class="week-input"
                type="number"
                :min="waveTiming.waveStartWeek"
                :max="period.endWeek"
                :disabled="activeWaveReadonly"
                v-model="period.startWeek"
                @change="updatePeriod(period)"
              />
            </td>
            <td>
              <input
                class="week-input"
                type="number"
                :min="period.startWeek"
                :max="waveTiming.waveEndWeek"
                :disabled="activeWaveReadonly"
                v-model="period.endWeek"
                @change="updatePeriod(period)"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="border-0">
              {{ $t("REPORT_DEADLINE") }}
            </th>
            <td>
              <input
                class="week-input"
                type="number"
                :min="waveTiming.waveStartWeek"
                :max="waveTiming.waveEndWeek"
                :disabled="activeWaveReadonly"
                v-model="waveTiming.reportDeadlineWeek"
                @change="updateModel(waveTiming)"
              />
            </td>
            <td></td>
          </tr>
          <tr v-for="(period, index) in lockedPeriods" :key="period.id">
            <th scope="row" class="border-0">
              {{ `${index + 1}. ` + $t("LOCKED_PERIOD") }}
            </th>
            <td>
              <input
                class="week-input"
                type="number"
                :min="waveTiming.waveStartWeek"
                :max="period.endWeek"
                :disabled="activeWaveReadonly"
                v-model="period.startWeek"
                @change="updatePeriod(period)"
              />
            </td>
            <td>
              <input
                class="week-input"
                type="number"
                :min="period.startWeek"
                :max="waveTiming.waveEndWeek"
                :disabled="activeWaveReadonly"
                v-model="period.endWeek"
                @change="updatePeriod(period)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-3 align-self-end">
        <div v-for="bar in bars" :key="bar.id">
          <div style="height: 60px; vertical-align: middle">
            <p class="sublines">{{ bar.label }}</p>
          </div>
        </div>
      </div>
      <div class="col-9">
        <g-gantt-chart
          date-format="YYYY-MM-DD"
          :chart-start="waveStart"
          :chart-end="waveEnd"
          precision="month"
          bar-start="start"
          bar-end="end"
          :grid="true"
          :row-height="60"
          @dragend-bar="onDragendBar($event.bar, $event.e, $event.movedBars)"
        >
          <g-gantt-row
            v-for="bar in bars"
            :key="bar.lable"
            :bars="[bar]"
            :highlight-on-hover="true"
            label=""
          >
            <template></template>
          </g-gantt-row>
        </g-gantt-chart>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";

const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default {
  name: "WaveTiming",
  components: { Switch },
  setup() {
    return {};
  },
  methods: {
    async updateModel(waveTiming) {
      if (
        isNaN(waveTiming.reportDeadlineWeek) ||
        waveTiming.reportDeadlineWeek === "" ||
        waveTiming.reportDeadlineWeek < 1 ||
        waveTiming.reportDeadlineWeek > 53
      ) {
        await this.$store.dispatch("waves/loadWaveTiming");
        return;
      }
      var model = {
        agreesWithTiming: waveTiming.agreesWithTiming,
        comment: waveTiming.comment,
        latestDateText: waveTiming.latestDateText,
        noAgreeComment: waveTiming.noAgreeComment,
        reportDeadlineWeek: waveTiming.reportDeadlineWeek,
      };
      await this.$store.dispatch("waves/updateWaveTiming", model);
      this.$forceUpdate();
    },
    async updatePeriod(period) {
      if (
        isNaN(period.startWeek) ||
        period.startWeek === "" ||
        period.startWeek < 1 ||
        period.startWeek > 53 ||
        isNaN(period.endWeek) ||
        period.endWeek === "" ||
        period.endWeek < 1 ||
        period.endWeek > 53
      ) {
        await this.$store.dispatch("waves/loadWaveTiming");
        return;
      }
      var model = {
        id: period.id,
        startWeek: period.startWeek,
        endWeek: period.endWeek,
      };
      await this.$store.dispatch("waves/updateWaveTimingPeriod", model);
      this.$forceUpdate();
    },
    async onDragendBar(bar, event, movedBars) {
      var model = {
        id: bar.id,
        startDate: bar.start,
        endDate: bar.end,
      };
      await this.$store.dispatch("waves/updateWaveTimingPeriodDate", model);
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.$forceUpdate();
    await this.$store.dispatch("waves/loadWaveTiming");
    await delay(100);
    this.$forceUpdate();
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
    activePeriods() {
      return this.$store.getters["waves/getWavePeriods"].filter((e) => {
        return e.name && !e.name.includes("Locked ");
      });
    },
    lockedPeriods() {
      return this.$store.getters["waves/getWavePeriods"].filter((e) => {
        return e.name && e.name.includes("Locked ");
      });
    },
    waveTiming() {
      return this.$store.getters["waves/getWaveTiming"];
    },
    waveStart() {
      return this.$store.getters["waves/getWaveTiming"].waveStart.split("T")[0];
    },
    waveEnd() {
      return this.$store.getters["waves/getWaveTiming"].waveEnd.split("T")[0];
    },
    bars() {
      let bars = [];
      this.$store.getters["waves/getWavePeriods"].forEach((period) => {
        bars.push({
          id: period.id,
          start: period.startDate.split("T")[0],
          end: period.endDate.split("T")[0],
          label: period.name,
          ganttBarConfig: {
            id: period.id,
            hasHandles: true,
            label: "Week " + period.startWeek + " to " + period.endWeek,
          },
        });
      });
      return bars;
    },
  },
};
</script>
<style scoped>
textarea {
  resize: none;
}
select {
  max-width: 200px;
}
.week-input {
  width: 150px;
}
</style>
