<template>
  <div class="my-4">
    <h3>{{ $t("AUDIO_AND_VIDEO_RECORDINGS") }}</h3>
    <p class="mb-4">{{ $t("AUDIO_AND_VIDEO_RECORDINGS_TEXT") }}</p>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTION_RECORDINGS_INTERESTING") }}</p>
      <div class="row">
        <div class="col-6 justify-content-center">
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.onsiteRecording"
            @change="updateModel"
          >
            <option value="" v-show="!model.onsiteRecording">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="audio">{{ $t("YES_AUDIO_RECORDING") }}</option>
            <option value="video">{{ $t("YES_VIDEO_RECORDING") }}</option>
            <option value="no">{{ $t("NOT_INTERESTED") }}</option>
          </select>
        </div>
        <div class="col-6 justify-content-center">
          <p class="sublines">{{ $t("OFF_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.offsiteRecording"
            @change="updateModel"
          >
            <option value="" v-show="!model.offsiteRecording">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="audio">{{ $t("YES_AUDIO_RECORDING") }}</option>
            <option value="no">{{ $t("NOT_INTERESTED") }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="askRecordingLegal()">
      <p class="sublines">{{ $t("QUESTION_RECORDINGS_LEGAL") }}</p>
      <Switch
        :initValue="model.recordingLegal"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.recordingLegal = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askRecordingLegal() && model.recordingLegal">
      <p class="sublines">{{ $t("SALES_EXECUTIVES_NAMES_ALLOWANCE") }}</p>
      <Switch
        :initValue="model.salesExecutivesNamesAllowance"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.salesExecutivesNamesAllowance = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askHowManyRecordings()">
      <p class="sublines mb-3">{{ $t("QUESTION_MANY_RECORDINGS") }}</p>
      <div class="row">
        <div
          class="col-6 justify-content-center px-5"
          v-show="askHowManyRecordingsOnsite()"
        >
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.onsiteRecordingType"
            @change="updateModel"
          >
            <option value="" v-show="!model.onsiteRecordingType">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="all">{{ $t("ALL_ONSITE_SHOPS") }}</option>
            <option value="selected">
              {{ $t("ONLY_SELECTED_ONSITE_SHOPS") }}
            </option>
          </select>
        </div>
        <div
          class="col-6 justify-content-center px-5"
          v-show="askHowManyRecordingsOffsite()"
        >
          <p class="sublines">{{ $t("OFF_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.offsiteRecordingType"
            @change="updateModel"
          >
            <option value="" v-show="!model.offsiteRecordingType">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="all">{{ $t("ALL_OFFSITE_SHOPS") }}</option>
            <option value="selected">
              {{ $t("ONLY_SELECTED_OFFSITE_SHOPS") }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="askRecordingComment()">
      <p class="sublines mb-3">{{ $t("QUESTION_SELECTED_SHOPS_COMMENT") }}</p>
      <div class="row">
        <div
          class="col-6 justify-content-center px-5"
          v-show="askOnsiteRecordingComment()"
        >
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <textarea
            v-model="model.onsiteRecordingComment"
            :disabled="activeWaveReadonly"
            class="form-control w-100"
            @change="updateModel"
          />
        </div>
        <div
          class="col-6 justify-content-center px-5"
          v-show="askOffsiteRecordingComment()"
        >
          <p class="sublines">{{ $t("OFF_SITE_SHOPS") }}</p>
          <textarea
            v-model="model.offsiteRecordingComment"
            :disabled="activeWaveReadonly"
            class="form-control w-100"
            @change="updateModel"
          />
        </div>
      </div>
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_REMARKS") }}</p>
      <textarea
        v-model="model.questionsAndRemarks"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("PAG_FEEDBACK") }}</p>
      <textarea
        v-model="model.porscheFeedback"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "AudioVideoRecordings",
  components: {
    Switch,
  },
  setup() {
    const store = useStore();
    let model = store.getters["waves/getRecordingSetup"];
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      await this.$store.dispatch("waves/updateRecordingSetup", this.model);
      this.model = this.$store.getters["waves/getRecordingSetup"];
      this.$forceUpdate();
    },
    askRecordingLegal: function () {
      return (
        this.model.onsiteRecording === "audio" ||
        this.model.onsiteRecording === "video" ||
        this.model.offsiteRecording === "audio"
      );
    },
    askHowManyRecordings: function () {
      return this.askRecordingLegal() && this.model.recordingLegal === true;
    },
    askHowManyRecordingsOnsite: function () {
      return (
        this.model.onsiteRecording === "audio" ||
        this.model.onsiteRecording === "video"
      );
    },
    askHowManyRecordingsOffsite: function () {
      return this.model.offsiteRecording === "audio";
    },
    askRecordingComment: function () {
      let self = this;
      return (
        self.askOnsiteRecordingComment() || self.askOffsiteRecordingComment()
      );
    },
    askOnsiteRecordingComment: function () {
      return (
        (this.model.onsiteRecording === "audio" ||
          this.model.onsiteRecording === "video") &&
        this.model.onsiteRecordingType === "selected" &&
        this.model.recordingLegal === true
      );
    },
    askOffsiteRecordingComment: function () {
      return (
        this.model.recordingLegal === true &&
        this.model.offsiteRecording === "audio" &&
        this.model.offsiteRecordingType === "selected"
      );
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
