<template>
  <div class="my-4">
    <h3>{{ $t("FEEDBACK_SHOPS") }}</h3>
    <p class="mb-4">{{ $t("FEEDBACK_SHOPS_TEXT") }}</p>
    <div class="mb-4">
      <p class="sublines mb-3">
        {{ $t("QUESTION_FEEDBACK_SHOPS_INTERESTING") }}
      </p>
      <div class="row">
        <div class="col-6">
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <Switch
            :initValue="model.onsiteFeedbackShopsInteresting"
            :disabled="activeWaveReadonly"
            v-on:changed="
              (checked) => {
                model.onsiteFeedbackShopsInteresting = checked;
                updateModel();
              }
            "
          />
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="askFeedbackShopsLegal()">
      <p class="sublines">{{ $t("QUESTION_FEEDBACK_SHOPS_LEGAL") }}</p>
      <Switch
        :initValue="model.feedbackShopsLegal"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.feedbackShopsLegal = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askFeedbackShopsTypeAndTime()">
      <p class="sublines">{{ $t("QUESTION_FEEDBACK_SHOPS_TYPE") }}</p>
      <div class="row">
        <div class="col-6" v-show="askOnsiteFeedbackShopsTypeAndTime()">
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.onsiteFeedbackShopsType"
            @change="updateModel"
          >
            <option value="" v-show="!model.onsiteFeedbackShopsType">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="all">{{ $t("ALL_ONSITE_SHOPS") }}</option>
            <option value="selected">
              {{ $t("ONLY_SELECTED_ONSITE_SHOPS") }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="askFeedbackShopsComment()">
      <p class="sublines mb-3">{{ $t("QUESTION_FEEDBACK_SHOPS_COMMENT") }}</p>
      <div class="row">
        <div
          class="col-6 justify-content-center px-5"
          v-show="askOnsiteFeedbackShopsComment()"
        >
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <textarea
            v-model="model.onsiteFeedbackShopsComment"
            :disabled="activeWaveReadonly"
            class="form-control w-100"
            @change="updateModel"
          />
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="askFeedbackShopsTypeAndTime()">
      <p class="sublines mb-3">{{ $t("QUESTION_FEEDBACK_SHOPS_TIME") }}</p>
      <div class="row">
        <div
          class="col-6 justify-content-center px-5"
          v-show="askOnsiteFeedbackShopsTypeAndTime()"
        >
          <p class="sublines">{{ $t("ON_SITE_SHOPS") }}</p>
          <select
            class="form-select"
            :disabled="activeWaveReadonly"
            v-model="model.onsiteFeedbackShopsTime"
            @change="updateModel"
          >
            <option value="" v-show="!model.onsiteFeedbackShopsTime">
              {{ $t("PLEASE_SELECT") }}
            </option>
            <option value="after-shopping">{{ $t("AFTER_SHOPPING") }}</option>
            <option value="second-visit">{{ $t("SECOND_VISIT") }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_REMARKS") }}</p>
      <textarea
        v-model="model.questionsAndRemarks"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("PAG_FEEDBACK") }}</p>
      <textarea
        v-model="model.porscheFeedback"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "FeedbackShops",
  components: {
    Switch,
  },
  setup() {
    const store = useStore();
    let model = store.getters["waves/getFeedbackShopSetup"];
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      await this.$store.dispatch("waves/updateFeedbackShopSetup", this.model);
      this.model = this.$store.getters["waves/getFeedbackShopSetup"];
      this.$forceUpdate();
    },
    askFeedbackShopsLegal: function () {
      return this.model.onsiteFeedbackShopsInteresting === true;
    },
    askFeedbackShopsTypeAndTime: function () {
      let self = this;
      return self.askOnsiteFeedbackShopsTypeAndTime();
    },
    askOnsiteFeedbackShopsTypeAndTime: function () {
      return (
        this.model.onsiteFeedbackShopsInteresting === true &&
        this.model.feedbackShopsLegal === true
      );
    },
    askFeedbackShopsComment: function () {
      let self = this;
      return self.askOnsiteFeedbackShopsComment();
    },
    askOnsiteFeedbackShopsComment: function () {
      return (
        this.model.onsiteFeedbackShopsInteresting === true &&
        this.model.feedbackShopsLegal === true &&
        this.model.onsiteFeedbackShopsType == "selected"
      );
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
