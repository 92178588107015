<template>
  <div class="my-4">
    <h3>{{ $t("LOCAL_WAVE") }}</h3>
    <p class="mb-4">{{ $t("LOCAL_WAVE_TEXT") }}</p>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTION_LOCAL_WAVE_PLANED") }}</p>
      <Switch
        :initValue="model.localWavePlanned"
        v-on:changed="
          (checked) => {
            model.localWavePlanned = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askQuestions()">
      <p class="sublines">{{ $t("PLEASE_GIVE_LOCAL_WAVE_INFORMATION") }}</p>
      <table class="table">
        <thead class="table-light">
          <tr>
            <th></th>
            <th>{{ $t("ON_SITE_SHOPS") }}</th>
            <th>{{ $t("OFF_SITE_SHOPS") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("NUMBER_LOCAL_WAVES") }}</td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.onsiteLocalWaves"
                class="form-control"
                @change="updateModel"
              />
            </td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.offsiteLocalWaves"
                class="form-control"
                @change="updateModel"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("NUMBER_PC_INCLUDED") }}</td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.onsiteNumberPCIncluded"
                class="form-control"
                @change="updateModel"
              />
            </td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.offsiteNumberPCIncluded"
                class="form-control"
                @change="updateModel"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("NUMBER_SHOPS_PER_PC") }}</td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.onsiteNumberOfShopsPerPC"
                class="form-control"
                @change="updateModel"
              />
            </td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.offsiteNumberOfShopsPerPC"
                class="form-control"
                @change="updateModel"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("PLANNED_TIMING") }}</td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.onsiteTiming"
                class="form-control"
                @change="updateModel"
              />
            </td>
            <td>
              <input
                :disabled="activeWaveReadonly"
                v-model="model.offsiteTiming"
                class="form-control"
                @change="updateModel"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("PLANNED_SCENARIO") }}</td>
            <td>
              <input
                v-model="model.onsiteScenario"
                :disabled="activeWaveReadonly"
                class="form-control"
                @change="updateModel"
              />
            </td>
            <td>
              <input
                v-model="model.offsiteScenario"
                :disabled="activeWaveReadonly"
                class="form-control"
                @change="updateModel"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("QUESTIONNAIRE") }}</td>
            <td>
              <select
                class="form-select"
                v-model="model.onsiteQuestionnaire"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.onsiteQuestionnaire">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="pag-central-wave-questionnaire">
                  {{ $t("PAG_CENTRAL_WAVE_QUESTIONNAIRE") }}
                </option>
                <option value="pag-new-questionnaire">
                  {{ $t("PAG_NEW_QUESTIONNAIRE") }}
                </option>
                <option value="own-questionnaire">
                  {{ $t("OWN_QUESTIONNAIRE") }}
                </option>
              </select>
            </td>
            <td>
              <select
                class="form-select"
                v-model="model.offsiteQuestionnaire"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.offsiteQuestionnaire">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="pag-central-wave-questionnaire">
                  {{ $t("PAG_CENTRAL_WAVE_QUESTIONNAIRE") }}
                </option>
                <option value="pag-new-questionnaire">
                  {{ $t("PAG_NEW_QUESTIONNAIRE") }}
                </option>
                <option value="own-questionnaire">
                  {{ $t("OWN_QUESTIONNAIRE") }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>{{ $t("AGENCY") }}</td>
            <td>
              <select
                class="form-select"
                v-model="model.onsiteAgency"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.onsiteAgency">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="concertare">
                  {{ $t("CONCERTARE") }}
                </option>
                <option value="msm">
                  {{ $t("MSM") }}
                </option>
                <option value="local-agency">
                  {{ $t("LOCAL_AGENCY") }}
                </option>
              </select>
            </td>
            <td>
              <select
                class="form-select"
                v-model="model.offsiteAgency"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.offsiteAgency">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="concertare">
                  {{ $t("CONCERTARE") }}
                </option>
                <option value="msm">
                  {{ $t("MSM") }}
                </option>
                <option value="local-agency">
                  {{ $t("LOCAL_AGENCY") }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>{{ $t("REPORTING") }}</td>
            <td>
              <select
                class="form-select"
                v-model="model.onsiteReporting"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.onsiteReporting">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="central-reportingtool">
                  {{ $t("CENTRAL_REPORTINGTOOL") }}
                </option>
                <option value="other">
                  {{ $t("OTHER") }}
                </option>
              </select>
            </td>
            <td>
              <select
                class="form-select"
                v-model="model.offsiteReporting"
                :disabled="activeWaveReadonly"
                @change="updateModel"
              >
                <option value="" v-show="!model.offsiteReporting">
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option value="central-reportingtool">
                  {{ $t("CENTRAL_REPORTINGTOOL") }}
                </option>
                <option value="other">
                  {{ $t("OTHER") }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-4" v-if="askQuestions()">
      <p class="sublines">{{ $t("QUESTION_PLANED_BUDGET") }}</p>
      <textarea
        v-model="model.budgetComent"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4" v-if="askQuestions()">
      <p class="sublines">{{ $t("QUESTION_DEALER_PARTICIPATION") }}</p>
      <textarea
        v-model="model.dealerParticipationComment"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_REMARKS") }}</p>
      <textarea
        v-model="model.questionsAndRemarks"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "LocalWave",
  components: {
    Switch,
  },
  async setup() {
    const store = useStore();
    let model = JSON.parse(
      JSON.stringify(store.getters["waves/getLocalWaveSetup"])
    );
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      if (
        isNaN(this.model.onsiteLocalWaves) ||
        this.model.onsiteLocalWaves < 0 ||
        this.model.onsiteLocalWaves > 1000000
      ) {
        this.model.onsiteLocalWaves = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].onsiteLocalWaves;
      } else if (this.model.onsiteLocalWaves === "") {
        this.model.onsiteLocalWaves = null;
      }

      if (
        isNaN(this.model.offsiteLocalWaves) ||
        this.model.offsiteLocalWaves < 0 ||
        this.model.offsiteLocalWaves > 1000000
      ) {
        this.model.offsiteLocalWaves = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].offsiteLocalWaves;
      } else if (this.model.offsiteLocalWaves === "") {
        this.model.offsiteLocalWaves = null;
      }

      if (
        isNaN(this.model.onsiteNumberOfShopsPerPC) ||
        this.model.onsiteNumberOfShopsPerPC < 0 ||
        this.model.onsiteNumberOfShopsPerPC > 1000000
      ) {
        this.model.onsiteNumberOfShopsPerPC = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].onsiteNumberOfShopsPerPC;
      } else if (this.model.onsiteNumberOfShopsPerPC === "") {
        this.model.onsiteNumberOfShopsPerPC = null;
      }

      if (
        isNaN(this.model.offsiteNumberOfShopsPerPC) ||
        this.model.offsiteNumberOfShopsPerPC < 0 ||
        this.model.offsiteNumberOfShopsPerPC > 1000000
      ) {
        this.model.offsiteNumberOfShopsPerPC = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].offsiteNumberOfShopsPerPC;
      } else if (this.model.offsiteNumberOfShopsPerPC === "") {
        this.model.offsiteNumberOfShopsPerPC = null;
      }

      if (
        isNaN(this.model.onsiteNumberPCIncluded) ||
        this.model.onsiteNumberPCIncluded < 0 ||
        this.model.onsiteNumberPCIncluded > 1000000
      ) {
        this.model.onsiteNumberPCIncluded = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].onsiteNumberPCIncluded;
      } else if (this.model.onsiteNumberPCIncluded === "") {
        this.model.onsiteNumberPCIncluded = null;
      }

      if (
        isNaN(this.model.offsiteNumberPCIncluded) ||
        this.model.offsiteNumberPCIncluded < 0 ||
        this.model.offsiteNumberPCIncluded > 1000000
      ) {
        this.model.offsiteNumberPCIncluded = this.$store.getters[
          "waves/getLocalWaveSetup"
        ].offsiteNumberPCIncluded;
      } else if (this.model.offsiteNumberPCIncluded === "") {
        this.model.offsiteNumberPCIncluded = null;
      }

      await this.$store.dispatch("waves/updateLocalWaveSetup", this.model);
      this.model = JSON.parse(
        JSON.stringify(this.$store.getters["waves/getLocalWaveSetup"])
      );
      this.$forceUpdate();
    },
    askQuestions() {
      return this.model.localWavePlanned === true;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>
