<template>
  <div class="my-4">
    <h3>{{ $t("ROUND_TABLE") }}</h3>
    <p class="mb-4">{{ $t("ROUND_TABLE_TEXT") }}</p>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTION_ROUND_TABLE_INTERESTING") }}</p>
      <Switch
        :initValue="model.roundTableInteresting"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.roundTableInteresting = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askRoundTableLegal()">
      <p class="sublines">{{ $t("QUESTION_ROUND_TABLE_LEGAL") }}</p>
      <Switch
        :initValue="model.roundTableLegal"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.roundTableLegal = checked;
            updateModel();
          }
        "
      />
    </div>
    <div class="mb-4" v-if="askRoundTableType()">
      <p class="sublines">{{ $t("QUESTION_ROUND_TABLE_TYPE") }}</p>
      <select
        class="form-select"
        :disabled="activeWaveReadonly"
        v-model="model.roundTableType"
        @change="updateModel"
      >
        <option value="" v-show="!model.roundTableType">
          {{ $t("PLEASE_SELECT") }}
        </option>
        <option value="offline">{{ $t("IN_PERSON") }}</option>
        <option value="online">{{ $t("ONLINE") }}</option>
      </select>
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTIONS_REMARKS") }}</p>
      <textarea
        v-model="model.questionsAndRemarks"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
    <div class="mb-4">
      <p class="sublines">{{ $t("PAG_FEEDBACK") }}</p>
      <textarea
        v-model="model.porscheFeedback"
        :disabled="activeWaveReadonly"
        class="form-control"
        @change="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "RoundTable",
  components: {
    Switch,
  },
  async setup() {
    const store = useStore();
    let model = store.getters["waves/getRoundTableSetup"];
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      await this.$store.dispatch("waves/updateRoundTableSetup", this.model);
      this.model = this.$store.getters["waves/getRoundTableSetup"];
      this.$forceUpdate();
    },
    askRoundTableLegal: function () {
      return this.model.roundTableInteresting === true;
    },
    askRoundTableType: function () {
      return (
        this.model.roundTableInteresting === true &&
        this.model.roundTableLegal === true
      );
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
select {
  max-width: 200px;
}
</style>
