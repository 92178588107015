<template>
  <div class="my-4">
    <h3>{{ $t("TIMING_REQUIREMENTS") }}</h3>
    <div class="mb-4">
      <p class="sublines">{{ $t("QUESTION_TIMING_REQUIREMENTS") }}</p>
      <Switch
        :initValue="model.hasTimingRequirements"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => {
            model.hasTimingRequirements = checked;
            updateModel();
          }
        "
      />
    </div>
    <div v-if="askTimingRequirements()">
      <div class="mb-4">
        <p class="sublines">{{ $t("REPORT_LATEST_DATE") }}</p>
        <input
          v-model="model.reportLatestDate"
          :disabled="activeWaveReadonly"
          type="date"
          class="form-control"
          @change="updateModel"
        />
      </div>
      <div class="mb-4">
        <p class="sublines">{{ $t("FIRST_WORK_DATE") }}</p>
        <input
          v-model="model.firstPossibleWorkDate"
          :disabled="activeWaveReadonly"
          type="date"
          class="form-control"
          @change="updateModel"
        />
      </div>
      <div class="mb-4">
        <p class="sublines">{{ $t("COMMENT_TIMING_REQUIREMENTS") }}</p>
        <textarea
          v-model="model.comment"
          :disabled="activeWaveReadonly"
          class="form-control"
          @change="updateModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "TimingRequirements",
  components: {
    Switch,
  },
  async setup() {
    const store = useStore();
    let model = store.getters["waves/getTimingRequirements"];
    return {
      model,
    };
  },
  computed: {
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    async updateModel() {
      await this.$store.dispatch("waves/updateTimingRequirements", this.model);
      this.model = this.$store.getters["waves/getTimingRequirements"];
      this.$forceUpdate();
    },
    askTimingRequirements: function () {
      return this.model.hasTimingRequirements === true;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
select {
  max-width: 200px;
}
</style>
